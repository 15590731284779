<template>
  <div
    v-if="rowsLength"
    class="d-flex align-center">
    <v-icon
      v-for="arrow in arrows"
      :key="arrow.icon"
      medium
      class="v-icon v-icon__control-arrows"
      :class="{
        'disabled-icon' : isLoadingImages
      }"
      @click="arrowControl(arrow.method)">
      {{ arrow.icon }}
    </v-icon>
    <p
      v-if="!isFocused"
      class="mb-0 row-title pointer"
      @click="isFocused = true">
      {{ selectedTypeTitle }}
      <v-icon
        color="#979797"
        size="20">
        arrow_drop_down
      </v-icon>
    </p>
    <v-autocomplete
      v-else
      ref="rows-switcher"
      v-model="selected"
      autofocus
      autocomplete="off"
      attach
      rounded
      color="#979797"
      :items="rows"
      item-text="type"
      item-value="id"
      hide-details
      class="v-autocomplete__rows-switcher ml-3"
      solo
      flat
      :disabled="isLoadingImages"
      hide-no-data
      style="max-width: 170px; width: auto"
      @focus="focusHandler"
      @blur="isFocused = false" />
  </div>
</template>
<script>
import {
  mapGetters, mapMutations, mapState,
} from 'vuex';
export default {
  name: 'ProjectDetailsRowExpandedViewRowsSwitcher',
  props: {
    isLoadingImages: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      arrows: {
        down: {
          icon: 'mdi-chevron-left',
          method: 'arrowLeft',
        },
        up: {
          icon: 'mdi-chevron-right',
          method: 'arrowRight',
        },
      },
      isFocused: false,
    };
  },
  computed: {
    ...mapGetters({
      rows: 'ScheduleRows/mappedDataRows',
    }),
    ...mapState({
      modalRowId: state => state.ProjectDetailsTableSchedule.modalRowId,
    }),
    selected: {
      get() {
        return this.modalRowId;
      },
      set(val) {
        const value = val ? val : this.modalRowId;
        this.setModalRowId(value);
      },
    },
    selectedTypeTitle() {
      const { selectedIndex = 0, rows = [] } = this;
      return rows[selectedIndex]?.type;
    },
    selectedIndex() {
      const { rows = [], selected } = this;
      const index = rows.findIndex(({ id } = {
      }) => id === selected);
      if (index === -1) return 0;
      return index;
    },
    rowsLength() {
      const { rows = [] } = this;
      return rows.length;
    },
    lastRowIndex() {
      const { rowsLength = 0 } = this;
      if (!rowsLength) return 0;
      return rowsLength - 1;
    },
  },
  methods: {
    ...mapMutations({
      setModalRowId: 'ProjectDetailsTableSchedule/setModalRowId',
    }),
    focusHandler() {
      this.isFocused = true;
      this.$refs['rows-switcher'].isMenuActive = true;
    },
    arrowControl(method) {
      if (this.isLoadingImages) {
        return;
      }
      if (!method) return;
      this[method]();
    },
    arrowLeft() {
      const { selectedIndex: idx, rows, lastRowIndex: last } = this;
      let index;
      if (idx <= 0) {
        index = last;
      } else {
        index = idx - 1;
      }
      this.selected = rows[index]?.id;
    },
    arrowRight() {
      const { selectedIndex: idx, rows, lastRowIndex: last } = this;
      let index;
      if (idx >= last) {
        index = 0;
      } else {
        index = idx + 1;
      }
      this.selected = rows[index]?.id;
    },
  },
};
</script>
<style scoped lang="scss">
  $main-color: #524F52;
  .disabled-icon {
    cursor: not-allowed !important;
  }
  .row-title {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    margin-left: 25px;
  }
  .v-icon {
    &__control-arrows {
      background: $main-color;
      border-radius: 3px;
      color: #FFFFFF;
      font-size: 21px;
      margin-right: 4px;
    }
  }
  .v-text-field, .v-input {
    height: 21px;
    ::v-deep &__control {
      border-radius: 3px;
    }
    ::v-deep &__slot {
      height: 21px;
      padding: 0 8px !important;
      background: transparent !important;
    }
    &--is-focused {
      ::v-deep .v-input__slot {
        background: $main-color !important;
      }
    }
    ::v-deep.v-select__slot {
      padding-top: 0 !important;
    }
    ::v-deep .v-menu__content {
      margin-top: 1px;
      box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      background: #605D60 !important;
      .v-list {
        background: transparent;
      }
    }
    ::v-deep input, ::v-deep .v-list-item__title {
      padding: 0 !important;
      font-size: 12px !important;
      line-height: 20px !important;
      color: #FFFFFF !important;
    }
    ::v-deep .v-list-item {
      height: 19px;
      min-height: 19px;
      &:not(.v-list-item--active):hover {
        &::before {
          opacity: 0.2;
        }
      }
      &--active {
        &::before {
          background: #979797;
          opacity: 1;
        }
      }
    }
  }
</style>
